<template>
  <div
    :id="`${id}-dropdown`"
    ref="mainRef"
    v-click-outside="handleBlur"
    class="relative"
    :class="[wrapperClass]"
    @keydown.up="handleActions('up')"
    @keydown.down="handleActions('down')"
    @keydown.enter="handleActions('enter')"
  >
    <AtomsInput
      ref="inputRef"
      v-model="inputText"
      v-bind="$attrs"
      wrapper-class="h-inherit"
      class="h-inherit"
      type="text"
      :placeholder="
        multiple && !_isEmpty(inputSelected)
          ? ''
          : defaultValue || $attrs.placeholder || label
      "
      :mobile-keyboard-type="mobileKeyboardType"
      :label="label"
      :model-type="modelType"
      :searchable="searchable"
      :dark-placeholder="darkPlaceholder"
      :light-placeholder="lightPlaceholder"
      :theme="theme"
      :readonly="$attrs.readonly || !searchable"
      :input-area-class="selectionClass"
      :chevron-config="chevronConfig"
      :error-message="errorMessage"
      :is-success="isSuccess"
      :hide-icon="hideDropdownIcon"
      :is-email-dropdown="isEmailDropdown"
      :class="{
        '!font-bold': !_isEmpty(inputText) && selectedDisplayBold
      }"
      @focus="handleFocus"
      @input="() => handleFilterListDebounced()"
    />

    <Teleport to="body">
      <div
        v-show="toggle"
        :id="`${id}-dropdown-list`"
        ref="listRef"
        :style="{
          left: listRect.left,
          top: listRect.top,
          width: listRect.width
        }"
        class="dropdown-list absolute w-full z-[999] pt-4 pb-[1.5rem] shadow-sm-3 rounded-[0.625rem] bg-white"
        :class="[
          {
            '!p-0': _isEmpty(filteredList)
          },
          dropdownClass
        ]"
        @click.stop
      >
        <div
          v-if="multiple"
          class="flex justify-between px-4 mb-4 bg-alert-info-fill font-bold text-xs leading-[1.023125rem]"
        >
          <span
            class="py-[0.125rem] cursor-pointer"
            @click="handleSelectAll"
          >
            SELECT ALL
          </span>

          <span
            class="py-[0.125rem] cursor-pointer"
            @click="handleClearAll"
          >
            CLEAR
          </span>
        </div>

        <ul
          v-if="!_isEmpty(filteredList)"
          ref="dropdownListRef"
          class="overflow-auto max-h-[356px]"
        >
          <li
            v-for="(item, itemKey) in filteredList"
            :key="itemKey"
            ref="dropdownListItemsRef"
            class="cursor-pointer flex px-4 py-1 flex-grow text-base hover:bg-blue-5 relative"
            :class="{
              '!bg-blue-10': itemKey === flags.arrowPosition,
              'bg-blue-5': checkSelected(item)
            }"
            @click="handleSelectItem(item)"
          >
            <!-- @keydown.enter="handleSelectItem(item)" -->
            <span
              v-if="multiple"
              class="relative overflow-hidden inline-flex place-items-center justify-center rounded-[0.25rem] border border-gray-400 min-w-[1.25rem] min-h-[1.25rem] max-h-[1.25rem] before:w-[1rem] before:h-[1rem] after:w-[1rem] after:h-[1rem] before:content-[''] before:absolute before:rounded-[0.25rem] after:content-[''] after:absolute after:bg-[url('~/public/icons/check.svg')] after:bg-no-repeat after:bg-center mr-[1.125rem]"
              :class="checkSelected(item) ? 'border-white bg-white before:border-white before:bg-dark after:block' : 'after:hidden'"
            >
            </span>

            <!-- eslint-disable vue/no-v-html -->
            <span v-html="handleDisplayStr((item && item[dataListDisplay]) || item)"></span>
          </li>
        </ul>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'

import _isEmpty from 'underscore/cjs/isEmpty.js'
import _size from 'underscore/cjs/size.js'
import _debounce from 'underscore/cjs/debounce.js'

defineOptions({
  name: 'MoleculesSelectDropdown',
  inheritAttrs: false
})

const props = defineProps({
  modelValue: {
    type: [String, Array, Object],
    default: ''
  },

  modelType: {
    type: String,
    default: 'dropdown',
    validator: value => ['text', 'dropdown'].includes(value)
  },

  multiple: {
    type: Boolean,
    default: false
  },

  searchable: {
    type: Boolean,
    default: false
  },

  hideDropdownIcon: {
    type: Boolean,
    default: false
  },

  label: {
    type: String,
    default: 'Label'
  },

  errorMessage: {
    type: String,
    default: ''
  },

  isSuccess: {
    type: Boolean,
    default: false
  },

  dataArr: {
    type: Array,
    default: () => []
  },

  // show dropdown list if character length is equal to characterDelay
  characterDelay: {
    type: [String, Number],
    default: 0,
    validator: value => !isNaN(value)
  },

  // show dropdown list if character length is equal to characterDelay
  enableSpecialCharacterDelay: {
    type: [Boolean, String],
    default: false
  },

  /**
   * limit list to be displayed
   */
  dataListLimit: {
    type: [String, Number],
    default: 0,
    validator: value => !isNaN(value)
  },

  // url to fetch array
  dataSrc: {
    type: String,
    default: ''
  },

  // key to access list of array on fetch
  dataSrcKey: {
    type: String,
    default: 'data'
  },

  // key used to display data in dropdown
  dataListDisplay: {
    type: [String, Number],
    default: 'name'
  },

  // key used to display data in input
  dataInputDisplay: {
    type: [String, Number],
    default: 'name'
  },

  /**
   * key used for emitting data to parent component
   * if value is empty it return the whole selected item
   */
  dataValue: {
    type: [String, Number],
    default: ''
  },

  customizeDataDisplay: {
    type: Function,
    default: null
  },

  theme: {
    type: String,
    default: 'default',
    validator: value => ['default', 'light'].includes(value)
  },

  /**
   * prop for overriding filter method and emiting
   * the value for external search filter
   */
  externalFilter: {
    type: Boolean,
    default: false
  },

  /**
   * validates data on focus
   * if empty, it becomes error
   */
  validateOnFocus: {
    type: Boolean,
    default: false
  },

  blurOnInput: {
    type: Boolean,
    default: true
  },

  selectionClass: {
    type: String,
    default: ''
  },

  chevronConfig: {
    type: Object,
    default: () => ({})
  },

  selectedDisplayBold: {
    type: Boolean,
    default: false
  },

  dropdownClass: {
    type: String,
    default: ''
  },

  // height of the wrapper or the whole component
  wrapperClass: {
    type: String,
    default: ''
  },

  defaultValue: {
    type: [String, Number, Array, Object],
    default: ''
  },

  darkPlaceholder: {
    type: Boolean,
    default: false
  },
  lightPlaceholder: {
    type: Boolean,
    default: false
  },

  isEmailDropdown: {
    type: Boolean,
    default: false
  },

  mobileKeyboardType: {
    type: String,
    default: 'text'
  }
})

const emit = defineEmits([
  'update',
  'update:model-value',
  'filter',
  'input',
  'email-blur',
  'api-options'
])

const $attrs = useAttrs()
const { devices } = deviceChecker()

const layoutsStore = useLayoutsStore()
const { scrollPosition } = storeToRefs(layoutsStore)

const flags = reactive({
  focus: false,
  focusCount: 0,
  arrowPosition: -1
})

const mainRef = ref()
const inputRef = ref()
const dropdownListRef = ref()
const dropdownListItemsRef = ref()

const listRef = ref()
const listRect = reactive({
  width: 0,
  top: 0,
  left: 0
})

const toggle = ref(false)

const inputText = ref()
const selected = reactive({
  values: [],
  input: []
})

const filteredList = ref()
const id = computed(() => generateUID())

const delay = (!props.characterDelay || isNaN(props.characterDelay)) ? 0 : +props.characterDelay

/**
 * returns the selected list
 * if there is an initial value
 */
const _selected = computed(() => {
  let selectedCount = 1

  if (props.multiple && ['array of arrays', 'array of objects'].includes(selectedType.value)) {
    selectedCount = _size(currValue.value)
  }

  if (props.isEmailDropdown) {
    return {
      input: inputText.value,
      values: inputText.value,
      inputText: inputText.value
    }
  }

  const tempList = []

  /**
   * adds item to tempList if its selected
   * and breaks the loop if `tempList` and `selectedCount`
   * has the same size
   */
  for (let i = 0; i < list.value.length; i++) {
    const item = list.value[i]

    if (checkSelected(item)) {
      tempList.push(item)
    }

    if (_size(tempList) === selectedCount) {
      break
    }
  }

  if (_isEmpty(tempList)) {
    return {
      input: [],
      values: []
    }
  }

  return {
    input: tempList.map(item => item?.[props.dataInputDisplay] ?? item),
    values: tempList.map(item => item?.[props.dataValue] ?? item),
    inputText: (() => {
      /**
       * if `props.multple` true
       * `inputText.value` is set to empty string
       *  instead `inputSelected` is displayed
       */
      if (props.multiple) {
        return ''
      }

      return tempList[0]?.[props.dataInputDisplay] ?? tempList[0]
    })()
  }
})

defineExpose({
  selected: _selected
})

const inputSelected = computed(() => {
  if (_isEmpty(selected.values) || !props.multiple) {
    return ''
  }

  const map = selected.values.map(item => item[props.dataInputDisplay] ?? item)

  return map.join(', ')
})

const dataList = ref([])

onMounted(async () => {
  await nextTick()
  calculatePosition()

  /**
   * move dataList handling to onMounted
   * to prevent undefined parentNode ssr bug
   */
  dataList.value = _isEmpty(props.dataSrc)
    ? []
    : await useFetch(props.dataSrc)
      .then(res => {
        const temp = res?.data?.value?.[props.dataSrcKey] ?? res?.data?.value ?? []
        emit('api-options', temp)
        if (!(isObject(temp) || isArray(temp))) {
          return []
        }

        return temp
      })

  initializeData()

  if (!_isEmpty(selected.values)) {
    const value = props.multiple
      ? selected.values
      : selected.values[0]

    emit('update:model-value', value)
    emit('update', value)
  }
})

/**
 * List of options
 * returns `dataList` if dataSrc is not empty
 * otherwise it returns `props.dataArr`
 */
const list = computed(() => (JSON.parse(JSON.stringify(props.dataSrc ? dataList.value : props.dataArr))))

const listUpdatedEmail = computed(() => {
  if (props.enableSpecialCharacterDelay) {
    return list.value?.map(item => ((inputText.value?.split('@')[0] || '') + '@') + item)
  }

  return list.value
})

const currValue = computed(() => {
  const temp = Array.isArray(selected.values)
    ? selected.values
    : [selected.values]

  const tempModelValue = Array.isArray(props.modelValue)
    ? props.modelValue
    : [props.modelValue]

  return !_isEmpty(temp) ? temp : tempModelValue
})

const selectedType = computed(() => {
  if (!_isEmpty(currValue.value)) {
    if (isArray(currValue.value) && isArray(currValue.value[0])) {
      return 'array of arrays'
    }

    if (isArray(currValue.value) && isObject(currValue.value[0])) {
      return 'array of object'
    }

    if (isArray(currValue.value)) {
      return 'array'
    }
  }

  return typeof currValue.value
})

watch(() => props.modelValue, val => {
  if (!val) {
    inputText.value = val
  } else {
    initializeData()
  }
}, { deep: true })

watch(inputText, val => {
  /**
   * emits `inputText.value` if its empty
   */

  if (!val) {
    selected.values = []
    selected.input = []

    emit('update:model-value', inputText.value)
    emit('update', inputText.value)
  }
}, { deep: true })

watch(() => props.dataArr, val => {
  if (props.externalFilter) {
    filteredList.value = val
  }
}, { deep: true })

watch(() => devices.width.value, () => calculatePosition())
watch(scrollPosition, () => calculatePosition())

/**
 * initializes data if there is an existing data
 */
function initializeData () {
  if (props.modelValue) {
    selected.values = _selected.value.values
    selected.input = _selected.value.input
    inputText.value = _selected.value.inputText
  }
}

/**
 * Filters list of options upon input or focus
 */
async function handleFilterList () {
  if (props.searchable) {
    handleShowDropdown()
  }

  if (props.externalFilter) {
    emit('filter', inputText.value)
    return
  }

  const text = (inputText.value && inputText.value.toString().toLowerCase()) || ''

  /**
   * returns the original list of options if
   * its not `searchable`
   */
  if (!props.searchable) {
    filteredList.value = list.value
    return
  }

  if (!(isObject(list.value) || isArray(list.value))) {
    return
  }

  /**
   * if `props.dataListLimit` is not empty
   * it returns a limited number of list options
   */
  if (+props.dataListLimit) {
    filteredList.value = list.value
      .filter(item => {
        return `${item?.[props.dataListDisplay] ?? item}`
          .toLowerCase()
          .includes(text)
      })
      .slice(0, +props.dataListLimit)
  } else if (!_isEmpty(props.enableSpecialCharacterDelay) || props.enableSpecialCharacterDelay) {
    emit('email-blur', inputText.value)
    filteredList.value = listUpdatedEmail.value.filter(item => {
      return `${item?.[props.dataListDisplay] ?? item }`
        .toLowerCase()
        .includes(text)
    })
  } else {
    filteredList.value = list.value.filter(item => {
      return `${item?.[props.dataListDisplay] ?? item}`
        .toLowerCase()
        .includes(text)
    })
  }

  await wait(10)
  flags.arrowPosition = 0
  offsetIndex.value = 0

  calculatePosition()
}
const handleFilterListDebounced = _debounce(handleFilterList, 400)

function handleSelectItem (item) {
  try {
    // check if item and input value if the same
    if (JSON.stringify(item) === JSON.stringify(inputText.value) && props.multiple) {
      handleBlur()
      return
    }

    const inputDisplay = JSON.parse(JSON.stringify(item))?.[props?.dataInputDisplay] ?? item
    const inputValue = JSON.parse(JSON.stringify(item))?.[props.dataValue] ?? item

    /**
     * empties the inputText value if
     * `props.searchable` and `props.multiple` is true
     * it shows the `selected-multiple-items` ref
     * instead with ellipsis
     */
    if (props.searchable && props.multiple) {
      inputText.value = ''
    }

    if (!props.multiple) {
      selected.values = inputValue
      selected.input = inputDisplay

      inputText.value = inputDisplay
    } else {
      /**
       * checkes if item is selected.
       * if its selected it adds it to the array
       * otherwise, it removes it in the list
       */
      const selectedIndex = selected.values.findIndex(key => JSON.stringify(key) === JSON.stringify(inputValue))

      if (selectedIndex === -1) {
        selected.values.push(inputValue)
        selected.input.push(inputDisplay)
      } else {
        selected.values.splice(inputValue, 1)
        selected.input.splice(inputDisplay, 1)
      }
    }

    handleBlur()

    emit('update:model-value', selected.values)
    emit('update', selected.values)
    emit('input', selected.values)
  } catch (error) {
    throw new Error(error)
  }
}

/**
 * if has `enableSpecialCharacterDelay` it checks the list after keyword show
 */
function shouldShowDropdown () {
  const specialCharacter = props.enableSpecialCharacterDelay

  if (specialCharacter) {
    return inputText.value?.includes(specialCharacter)
  }

  return inputSelected.value || _size(inputText.value) >= delay
}

/**
 * if has `characterDelay` it checks if the keyword length is
 * greater than or equal to delay number before it shows the list
 */
function handleShowDropdown () {
  if (shouldShowDropdown()) {
    toggle.value = true
    calculatePosition()
  }
}

function handleSelectAll () {
  selected.input = list.value.map(item => item?.[props.dataInputDisplay] ?? item)
  selected.values = list.value.map(item => item?.[props.dataValue] ?? item)

  emit('update:model-value', selected.values)
  emit('update', selected.values)
}

function handleClearAll () {
  inputText.value = ''
  selected.input = []
  selected.values = []

  emit('update:model-value', selected.values)
  emit('update', selected.values)
}

/**
 * returns customized string to display
 * in options list
 */
function handleDisplayStr (str) {
  if (props.customizeDataDisplay) {
    return props?.customizeDataDisplay(str)
  }

  if (Array.isArray(str)) {
    return str.join(', ')
  }

  return str
}

/**
 * checks if options is selected or not
 * returns Boolean
 */
function checkSelected (option) {
  if (_isEmpty(option) || _isEmpty(currValue.value)) {
    return false
  }

  /**
   * only if `props.multple` is false
   * and if both open and model value is Array
   */

  if (
    Array.isArray(option) &&
    Array.isArray(currValue.value) &&
    _size(option) && _size(currValue.value) &&
    JSON.stringify(option).toLowerCase() === JSON.stringify(currValue.value).toLowerCase()
  ) {
    return true
  }

  return currValue.value.findIndex(item => {
    if ([undefined, null].includes(item)) {
      return ''
    }

    const data = {
      itemDisplay: item?.[props.dataInputDisplay] ?? item,
      optionDisplay: option?.[props.dataInputDisplay] ?? option,

      itemValue: item?.[props.dataValue] ?? item,
      optionValue: option?.[props.dataValue] ?? option,

      defaultValue: props.defaultValue?.[props.dataValue] ?? props.defaultValue
    }

    const inputDisplay = JSON.stringify(data.itemDisplay).toLowerCase()
    const optionDisplay = JSON.stringify(data.optionDisplay).toLowerCase()

    const inputValue = JSON.stringify(data.itemValue || data.defaultValue).toLowerCase()
    const optionValue = JSON.stringify(data.optionValue).toLowerCase()

    return (inputDisplay === optionDisplay) ||
      (inputValue === optionValue)
  }) !== -1
}

/**
 * This is to check wether dropdownlist is inside viewport,
 * calculates the position the dropdownlist and updates the
 * top and left positions.
 */
function calculatePosition () {
  const _margin = 10
  const _navHeight = document.querySelector('#main-nav')
    ? document.querySelector('#main-nav')?.clientHeight
    : 56

  const _parent = getElOffset(mainRef.value)
  const _input = getElOffset(inputRef.value?.$el)
  const _list = getElOffset(listRef.value)

  const _inputRect = inputRef.value?.$el?.getBoundingClientRect()

  const _windowHeight = window.innerHeight || document.documentElement.clientHeight

  const viewport = (() => {
    return {
      top: (_input.top - _list.height - _margin - _navHeight) >= 0,
      bottom: (_inputRect?.bottom + _list.height + _margin) <= _windowHeight
    }
  })()

  /**
   * checks is element is still inside the
   * viewport and if not repositions the element
   */
  const newPos = {
    top: _parent.top + _input.height + _margin,
    left: _parent.left,
    width: _parent.width
  }

  if (viewport.bottom || (!viewport.bottom && !viewport.top)) {
    newPos.top = _parent.top + _input.height + _margin
  } else {
    // moves the dropdownlist at the top if it doesn't fit the viewport
    newPos.top = _parent.top - _margin - _list.height - 10
  }

  listRect.top = `${newPos?.top}px`
  listRect.left = `${newPos?.left}px`
  listRect.width = `${newPos?.width}px`
}

async function handleFocus () {
  flags.focus = 1
  flags.enterCount = 0
  flags.focusCount++

  handleShowDropdown()

  await wait(1)
  await handleFilterList()
}

function handleBlur () {
  flags.focus = false
  flags.arrowPosition = 0

  toggle.value = false
  inputRef.value?.$el?.blur()
  if (props.isEmailDropdown) {
    emit('email-blur', inputText.value)
  }
}

const offsetIndex = ref(0)
function handleActions (action) {
  const funcMap = getKey(action, {
    'down,up': () => {
      if (action === 'up' && flags.arrowPosition > 0) {
        flags.arrowPosition = flags.arrowPosition - 1
      } else if (action === 'down' && flags.arrowPosition < (_size(filteredList.value) - 1)) {
        flags.arrowPosition = flags.arrowPosition + 1
      }

      const _el = dropdownListItemsRef.value?.[flags.arrowPosition]
      const _list = dropdownListRef.value

      const _elRect = _el?.getBoundingClientRect()
      const _listRect = _list?.getBoundingClientRect()

      const offset = _elRect?.top - _listRect?.top

      if ((offset + 10) >= _listRect?.height) {
        offsetIndex.value++
      } else if (offset < 0) {
        offsetIndex.value--
      }

      if (offsetIndex.value !== -1) {
        _list?.scrollTo({
          top: _listRect.height * offsetIndex.value,
          behavior: 'smooth'
        })
      }
    },

    enter: () => {
      const item = filteredList.value[flags.arrowPosition]

      if (item) {
        handleSelectItem(item)
      }
    }
  })

  if (funcMap?.()) {
    funcMap?.()
  }
}
</script>
